@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.intro {
    z-index: 100;
  }

  canvas {
    width: 100%;
    height: 100%;
  }
}
